
  .select-custom-dropdown{
    /* border: 2px solid; */
    border-radius: 0px;
    border: 1px solid #000000;
    font-size: 16px;
    width: 100%;
    transition: width 0.3s;
    padding: 2px; 
    outline: none;
    min-height: 48px;
  }
.select-custom-div { 
    width: 100%;
    text-align: right;
    position: relative;
    font-size: 16px;
    border: none;
  }
  .select-custom-dropdown:focus{
    width: 100%;
    outline: none;
    border: 1px solid #08a1b6;
  }
  .select-custom-label{
    font-Size:16px !important
  }
 