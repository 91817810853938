.insurance
{
/* border: 1px solid; */
padding: 1%;
}

.title {
  font-family: Apercu Pro;
  font-size: 40px;
  line-height: 54px;
  color: #000000;
}
.th-td{
  min-width: 210px;
  /* min-width: 210%; */
  font-family: Apercu Pro;
 font-size: 85%; 
 padding-bottom: 0; 

}

