

.container{
    margin-left: 1%;
    /* margin-right: -20%; */
    /* width: 100%; */
}
.th-td{
    min-width: 210px;
    font-family: Apercu Pro;
   font-size: 16px;
   padding-bottom: 1; 

}