.date-picker {
  & >div{
    width: 100%;
    & >div{
      & > input{
       border: 1px solid #000000
      }
    }
  }
}

.date-picker-error {
  width: "130%";
  outline: "none";
  border: "2px solid  #f00";
  border-radius: 2;
  min-height: "48px";
  width: "inherit";
  & >div{
    width: 100%;
    & >div{
      & > input{
       border: 1px solid #f00
      }
    }
  }
}
.red-border {
  border-color: #f00;
  width: "inherit";
}
