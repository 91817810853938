// rate-search styles
@import '../../../../../modules//styles/variables';


.rate-search {
  position: relative;  
  padding: 0 $primaryLeftPadding;
  height: 4rem;
  display: flex;
  flex-direction: column; 
  width: 40rem;
}

.rate-search__label {
  visibility: hidden;
  display: none;
}

.search-group {
  display: flex;  
}



.rate-search__input {
  font-size: 16px;
  width: 100%;
  border: solid 1px;
  border-left: 0;
  background: white;
  
  height: 3rem;  
  padding: 0 1rem 0 2rem;    
  &:focus {
    box-shadow: 0px 0px 20px #4a90e2;
    border: #4a90e2 1px solid;
  }
  
}
::placeholder {
  color: rgb(170, 159, 159);
}

.rate-checkboxs {
  display: flex;  
  height: 4rem;
  // align-items: center;
  font-size: 16px;  
  min-width: 36rem; 
  
}

.rate-checkbox {  
  // margin-right: 1.3rem;
  margin-left:1rem ;
  min-width: 5rem; 
  & >label input{
    margin-right: 0.8rem;
  }
  &>label span{
    margin-top: 1px;
  }
}

.title {
  font-family: Apercu Pro;
  font-size: 40px;
  line-height: 54px;
  color: #000000;
}
.th-td{
  min-width: 210px;
  /* min-width: 210%; */
  font-family: Apercu Pro;
 font-size: 85%; 
 padding-bottom: 0; 

}



/* .search-border-active {
  box-shadow: 0px 0px 20px #4a90e2;
  border: #4a90e2 1px solid;
} */
