/* input:disabled{
    background:gainsboro;
  } */

input:focus{
    width: 100%;
    outline: none;
    // border: 1px solid #08a1b6; 
}
input{
  min-height: 48px;
  width:100%;
}