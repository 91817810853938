.certification
{
padding:1%;
}
.certTitle{
 font-family: Apercu Pro;
font-size: 40px;
// line-height: 54px;  
color: #000000; 
// width:90%
}
