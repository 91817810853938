@import '../../../../../modules/styles//variables';



.table-content {
  display: block;
  max-width: 100%;  
  height: calc(100% - 1rem);  
  border-top: none;
  // padding-left: $primaryLeftPadding;

  .table {      
    height: calc(100% - 3.5rem);
    width: 100%;
    
    font-size: $font-size-desktop;
    
    & > thead {
      text-align: left;
      border-bottom: 1px solid rgb(242, 242, 242); 
      border-top: none;     
      & > tr {        
        & > th {
          padding: 1rem 0.5rem;
          max-width:65rem ; 
          // min-width:12rem ;  
          
          &.sort-asc {
            box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6);
          }

          &.sort-desc {
            box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6);
          }
        }
      }
    }

    & > tbody {
      text-align: left;   
      display: block;
      // overflow:auto;
      height: calc(100% - 0.8rem);
      tr:not(.-padRow):hover{
        background: rgba(0, 0, 0, 0.05);
        cursor: pointer;
      }
      & > tr {
        border-bottom: 1px solid rgba(14, 3, 3, 0.05);
        .-striped {
          background: rgba(0, 0, 0, 0.03);
        }
        .-highlight {
          background: rgba(0, 0, 0, 0.05);
        }
        & > td {
          // padding: 1rem 0.5rem;
          // min-width:12rem ;
          max-width:65rem ; 

          &.no-data-text {
            text-align: center;
            font-size: $text-mega;
            color: grey;
            font-weight: 500;
          }
        }
      }
    }
  }

  .pagination {
    display: flex;
    flex-direction: row;
    justify-self: flex-end;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    padding: 0.5rem;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    border-top: 2px solid rgba(0, 0, 0, 0.1); 
    height: 3.5rem;

    .btn {
      flex: 1;
      webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      display: block;
      width: 100%;
      height: 100%;
      border: 0;
      border-radius: 3px;
      padding: 6px;
      font-size: 1em;
      color: rgba(0, 0, 0, 0.6);
      background: rgba(0, 0, 0, 0.1);
      transition: all 0.1s ease;
      cursor: pointer;
      outline: none;

      &:disabled {
        opacity: 0.5;
        cursor: default;
        background-color: rgba(0, 0, 0, 0.1);
        color:  rgba(0, 0, 0, 0.6);
      }

      &:not([disabled]):hover {
        background: rgba(0, 0, 0, 0.3);
        color: #fff;
      }
    }

    .pagination-center {
      display: flex;
      flex: 1;
      justify-content: space-evenly;
      align-items: stretch;
      flex-wrap: wrap;
      padding: 3px;

      .pagination-pageInfo {
        display: inline-block;
        margin: 3px 10px;
        white-space: nowrap;

        .pagination-pageJump {
          display: inline-block;

          & > input {
            border: 1px solid rgba(0, 0, 0, 0.1);
            background: #fff;
            padding: 5px 7px;
            font-size: inherit;
            border-radius: 3px;
            font-weight: 400;
            outline: none;
            width: 70px;
            text-align: center;
            margin: 0px 5px;
          }
        }
      }

      .select-wrap {
        margin: 3px 10px;
        & > select {
          border: 1px solid rgba(0, 0, 0, 0.1);
          background: #fff;
          padding: 5px 7px;
          font-size: inherit;
          border-radius: 3px;
          font-weight: 400;
          outline: none;
        }
      }
    }
  }
}

tr.-odd td{
  background: rgba(0, 0, 0, 0.03);
}
tr.-active td{
  background: rgba(0, 0, 0, 0.03);
}
tr.-first {
  border-top: none;
}

.tbody.tr:not(.-padRow):hover{
  background: rgba(0, 0, 0, 0.05);
}

.circleText{
  border-radius: 50%;
  font-size: 10px;
  padding: 0.2rem;
  vertical-align: middle;
  text-align: center;
  border: solid 1px;
}

.bgcolor_rejected{
  background-color: #db1b1b;
  color: white;
  padding: 0.3rem;
}

.bgcolor_exception{
  background-color: #e6e6eb;  
  padding: 0.3rem;
}

.bgcolor_expired{
  background-color: #f9d51c;
  padding: 0.3rem;  
}

.bgcolor_escalation{
  background-color: black;
  color: white;
  padding: 0.3rem;  
}
