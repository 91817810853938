/* .App {
  font-family: sans-serif;
  text-align: center;
}

body {
  background: white;
} */
.labelInput{
  font-size: 100% !important;
}
.searchDiv {
  width: 100%;
  // text-align: right;
  position: relative;
  font-size: 17px;
  border-radius: 0px;
  outline: none;
  min-height:48px;

}
.input-box {
  font-size: 17px;
  width: 100%;
  transition: width 0.3s;
  outline:none;
  min-height:48px;
  border-radius: 0px;
  border: 1px solid #000000;

}
.input-box:focus {
  width: 100%;
  outline: none;
  border: 1px solid #08a1b6;
}

.search-btn:hover {
  outline: none;
  opacity: 0.4;
  cursor: pointer;
}
.search-btn:focus {
  outline: none;
  opacity: 0.6;
}

ul.options {
  display: block;
  list-style: none;
  transition: width 0.3s;
  // margin: auto;
  // position: relative;
  padding-inline-start: 0px;
   max-height: 250px; 
  position: absolute;
  z-index: 6;
  background-color: rgb(255, 255, 255);
  max-width: 90%;
  // margin-top: 10%;  
  border: 1px solid #08a1b6;
  border-top: none;
}

ul.options li {
  display: block;
  background: white;
  margin: 5px auto;
  padding: 5px;
  font-size: 1rem;
  border-radius:0px;
}
ul.options li:hover {
  font-weight: bold;
  color: #00b4cc;
  cursor: pointer;
  transition: 0.3s all;
}

ul.options li.option-active {
  background: rgba(245, 245, 245, 0.308);
  font-size: 1rem;
  color: #00b4cc;
}
.no-options {
  color: red;
}
