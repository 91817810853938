.labels-lg {
  font-size: 20px ;
}
.labels-sm {
  font-size: 14px ;
}
.labels-xsm {
  font-size: 12px ;
}
.labels-md {
  font-size: 16px ;
}
.labels-xlg {
  font-size: 30px ;
}
.labels-title {
  font-size: 40px ;
}
