$primaryColor: #377A80;
$disabledBackground: #e6e6eb;
$disabledTextColor: #60676c;
$backgroundColor: #ffffff;
$borderColor: #000000;
$clearColor: #377a80;

$greyBackground: #f2f2f2;

$successBackgroundColor: #37e7a7;
$successTextColor: #000000;

$errorBackgroundColor: #db1b1b;
$errorTextColor: #ffffff;

$warningBackgroundColor: #ffe977;
$warningTextColor: #000000;

$primaryLeftPadding: 2rem;

// Typography Size Helpers
$text-milli: 0.7rem;
$text-centi: 0.9rem;
$text-default: 1rem;
$text-kilo: 1.25rem;
$text-mega: 1.5rem;
$text-giga: 2rem;
$text-tera: 2.5rem;
$text-peta: 3.5rem;

// Typography Weight Helpers

$text-weight-default: 400;
$text-weight-medium: 600;
$text-weight-bold: 700;
$font-size-desktop: 16px;
$font-size-tablet: 13px; //This will adjust fontsize and all rem units defined in the layout
$font-size-mobile: 12px; //This will adjust fontsize and all rem units defined in the layout

$borderRadius: 6px;
